import { action } from "typesafe-actions";
import { ProductActionTypes } from "./products.model";

export const productGetRequest = (req) =>
  action(ProductActionTypes.PRODUCTS_GET_REQUEST, req);
export const productGetSuccess = (res) =>
  action(ProductActionTypes.PRODUCTS_GET_SUCCESS, res);
export const productGetFailure = (err) =>
  action(ProductActionTypes.PRODUCTS_GET_FAILURE, err);

export const prodCategoryGetRequest = (req) =>
  action(ProductActionTypes.PRODUCTCATEGORY_GET_REQUEST, req);
export const prodCategoryGetSuccess = (res) =>
  action(ProductActionTypes.PRODUCTCATEGORY_GET_SUCCESS, res);
export const prodCategoryGetFailure = (err) =>
  action(ProductActionTypes.PRODUCTCATEGORY_GET_FAILURE, err);

export const BrandDataGetRequest = (req) =>
  action(ProductActionTypes.GETBRANDS_GET_REQUEST, req);
export const BrandDataGetSuccess = (res) =>
  action(ProductActionTypes.GETBRANDS_GET_SUCCESS, res);
export const BrandDataGetFailure = (err) =>
  action(ProductActionTypes.GETBRANDS_GET_FAILURE, err);

export const productDataEmailPostRequest = (req) =>
  action(ProductActionTypes.MAILPRODUCTDETIALS_GET_REQUEST, req);
export const productDataEmailPostSuccess = (res) =>
  action(ProductActionTypes.MAILPRODUCTDETIALS_GET_SUCCESS, res);
export const productDataEmailPostFailure = (err) =>
  action(ProductActionTypes.MAILPRODUCTDETIALS_GET_FAILURE, err);

export const filterProductGetRequest = (req) =>
  action(ProductActionTypes.FILTERPRODUCTS_GET_REQUEST, req);
export const filterProductGetSuccess = (res) =>
  action(ProductActionTypes.FILTERPRODUCTS_GET_SUCCESS, res);
export const filterProductGetFailure = (err) =>
  action(ProductActionTypes.FILTERPRODUCTS_GET_FAILURE, err);

export const resetInitialstate = (req) =>
  action(ProductActionTypes.STATE_RESET_REQUEST, req);

import logo from "../../src/assets/imgs/template/logo.svg";
import en from "../../src/assets/imgs/template/en.svg";
import armchair from "../../src/assets/imgs/page/homepage6/ceiling-fan.png";
import bathhub from "../../src/assets/imgs/page/homepage6/bulb.png";
import bed from "../../src/assets/imgs/page/homepage6/breaker (1).png";
import office from "../../src/assets/imgs/page/homepage6/circuit.png";
import chair from "../../src/assets/imgs/page/homepage6/electricity.png";
import chest from "../../src/assets/imgs/page/homepage6/fan6.png";
import dressing from "../../src/assets/imgs/page/homepage6/fuse-box.png";
import lamp from "../../src/assets/imgs/page/homepage6/heater.png";
import sofa from "../../src/assets/imgs/page/homepage6/holder.png";
import storage from "../../src/assets/imgs/page/homepage6/plug (1).png";
import electric from "../../src/assets/imgs/page/homepage6/socket (1).png";
import wardrobe from "../../src/assets/imgs/page/homepage6/water-boiler.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CatAddSelector } from "../store/category/category.model";
import { getCatRequest } from "../store/category/category.action";
import { API_BASE } from "../services/config";
import { createProductPayload } from "../utils/utils";
import { productSelector } from "../store/products/products.model";
import { productGetRequest } from "../store/products/products.action";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCatData } = useSelector(CatAddSelector);
  const { getProducts, mailSendSuccess,getCategoryList,getBrandList } = useSelector(productSelector);
  
  console.log(getCatData,getProducts, "tyhjk");
  useEffect(() => {
    dispatch(getCatRequest({}));
    const payload = createProductPayload(0, 0, "", "", "");
    // dispatch(productGetRequest(payload));
  }, []);
  return (
    <>
      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="mobile-logo">
              <a className="d-flex" href="index.html">
                <img alt="Ecom" src="assets/imgs/template/logo.svg" />
              </a>
            </div>
            <div className="perfect-scroll">
              <div className="mobile-menu-wrap mobile-header-border">
                <nav className="mt-15">
                  <ul className="mobile-menu font-heading">
                    <li className="has-children">
                      <a className="active" href="index.html">
                        Home
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="index.html">Homepage - 1</a>
                        </li>
                        <li>
                          <a href="index-2.html">Homepage - 2</a>
                        </li>
                        <li>
                          <a href="index-3.html">Homepage - 3</a>
                        </li>
                        <li>
                          <a href="index-4.html">Homepage - 4</a>
                        </li>
                        <li>
                          <a href="index-5.html">Homepage - 5</a>
                        </li>
                        <li>
                          <a href="index-6.html">Homepage - 6</a>
                        </li>
                        <li>
                          <a href="index-7.html">Homepage - 7</a>
                        </li>
                        <li>
                          <a href="index-8.html">Homepage - 8</a>
                        </li>
                        <li>
                          <a href="index-9.html">Homepage - 9</a>
                        </li>
                        <li>
                          <a href="index-10.html">Homepage - 10</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="shop-grid.html">Shop</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="shop-grid.html">Shop Grid</a>
                        </li>
                        <li>
                          <a href="shop-grid-2.html">Shop Grid 2</a>
                        </li>
                        <li>
                          <a href="shop-list.html">Shop List</a>
                        </li>
                        <li>
                          <a href="shop-list-2.html">Shop List 2</a>
                        </li>
                        <li>
                          <a href="shop-fullwidth.html">Shop Fullwidth</a>
                        </li>
                        <li>
                          <a href="shop-single-product.html">Single Product</a>
                        </li>
                        <li>
                          <a href="shop-single-product-2.html">
                            Single Product 2
                          </a>
                        </li>
                        <li>
                          <a href="shop-single-product-3.html">
                            Single Product 3
                          </a>
                        </li>
                        <li>
                          <a href="shop-single-product-4.html">
                            Single Product 4
                          </a>
                        </li>
                        <li>
                          <a href="shop-cart.html">Shop Cart</a>
                        </li>
                        <li>
                          <a href="shop-checkout.html">Shop Checkout</a>
                        </li>
                        <li>
                          <a href="shop-compare.html">Shop Compare</a>
                        </li>
                        <li>
                          <a href="shop-wishlist.html">Shop Wishlist</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="shop-vendor-list.html">Vendors</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="shop-vendor-list.html">Vendors Listing</a>
                        </li>
                        <li>
                          <a href="shop-vendor-single.html">Vendor Single</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="#">Pages</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="page-about-us.html">About Us</a>
                        </li>
                        <li>
                          <a href="page-contact.html">Contact Us</a>
                        </li>
                        <li>
                          <a href="page-careers.html">Careers</a>
                        </li>
                        <li>
                          <a href="page-term.html">Term and Condition</a>
                        </li>
                        <li>
                          <a href="page-register.html">Register</a>
                        </li>
                        <li>
                          <a href="page-login.html">Login</a>
                        </li>
                        <li>
                          <a href="page-404.html">Error 404</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="blog.html">Blog</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="blog.html">Blog Grid</a>
                        </li>
                        <li>
                          <a href="blog-2.html">Blog Grid 2</a>
                        </li>
                        <li>
                          <a href="blog-list.html">Blog List</a>
                        </li>
                        <li>
                          <a href="blog-big.html">Blog Big</a>
                        </li>
                        <li>
                          <a href="blog-single.html">
                            Blog Single - Left sidebar
                          </a>
                        </li>
                        <li>
                          <a href="blog-single-2.html">
                            Blog Single - Right sidebar
                          </a>
                        </li>
                        <li>
                          <a href="blog-single-3.html">
                            Blog Single - No sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="page-contact.html">Contact</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <div className="mobile-header-top">
                  <div className="user-account">
                    <a href="page-account.html">
                      <img src="assets/imgs/template/ava_1.png" alt="Ecom" />
                    </a>
                    <div className="content">
                      <h6 className="user-name">
                        Hello<span className="text-brand"> Steven !</span>
                      </h6>
                      <p className="font-xs text-muted">
                        You have 3 new messages
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="mobile-menu">
                  <li>
                    <a href="page-account.html">My Account</a>
                  </li>
                  <li>
                    <a href="page-account.html">Order Tracking</a>
                  </li>
                  <li>
                    <a href="page-account.html">My Orders</a>
                  </li>
                  <li>
                    <a href="page-account.html">My Wishlist</a>
                  </li>
                  <li>
                    <a href="page-account.html">Setting</a>
                  </li>
                  <li>
                    <a href="page-login.html">Sign out</a>
                  </li>
                </ul>
              </div>
              <div className="mobile-banner">
                <div className="bg-5 block-iphone">
                  <span className="color-brand-3 font-sm-lh32">
                    Starting from $899
                  </span>
                  <h3 className="font-xl mb-10">iPhone 12 Pro 128Gb</h3>
                  <p className="font-base color-brand-3 mb-10">Special Sale</p>
                  <a className="btn btn-arrow" href="shop-grid.html">
                    learn more
                  </a>
                </div>
              </div>
              <div className="site-copyright color-gray-400 mt-30">
                Copyright 2022 &copy; Ecom - Marketplace Template.
                <br />
                Designed by
                <a href="http://alithemes.com" target="_blank">
                  &nbsp; AliThemes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="mobile-logo">
              <a className="d-flex" href="index.html">
                <img alt="Ecom" src={logo} />
              </a>
            </div>
            <div className="perfect-scroll">
              <div className="mobile-menu-wrap mobile-header-border">
                <nav className="mt-15">
                  <ul className="mobile-menu font-heading">
                    <li className="has-children">
                      <a className="active" href="index.html">
                        Home
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="index.html">Homepage - 1</a>
                        </li>
                        <li>
                          <a href="index-2.html">Homepage - 2</a>
                        </li>
                        <li>
                          <a href="index-3.html">Homepage - 3</a>
                        </li>
                        <li>
                          <a href="index-4.html">Homepage - 4</a>
                        </li>
                        <li>
                          <a href="index-5.html">Homepage - 5</a>
                        </li>
                        <li>
                          <a href="index-6.html">Homepage - 6</a>
                        </li>
                        <li>
                          <a href="index-7.html">Homepage - 7</a>
                        </li>
                        <li>
                          <a href="index-8.html">Homepage - 8</a>
                        </li>
                        <li>
                          <a href="index-9.html">Homepage - 9</a>
                        </li>
                        <li>
                          <a href="index-10.html">Homepage - 10</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="shop-grid.html">Shop</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="shop-grid.html">Shop Grid</a>
                        </li>
                        <li>
                          <a href="shop-grid-2.html">Shop Grid 2</a>
                        </li>
                        <li>
                          <a href="shop-list.html">Shop List</a>
                        </li>
                        <li>
                          <a href="shop-list-2.html">Shop List 2</a>
                        </li>
                        <li>
                          <a href="shop-fullwidth.html">Shop Fullwidth</a>
                        </li>
                        <li>
                          <a href="shop-single-product.html">Single Product</a>
                        </li>
                        <li>
                          <a href="shop-single-product-2.html">
                            Single Product 2
                          </a>
                        </li>
                        <li>
                          <a href="shop-single-product-3.html">
                            Single Product 3
                          </a>
                        </li>
                        <li>
                          <a href="shop-single-product-4.html">
                            Single Product 4
                          </a>
                        </li>
                        <li>
                          <a href="shop-cart.html">Shop Cart</a>
                        </li>
                        <li>
                          <a href="shop-checkout.html">Shop Checkout</a>
                        </li>
                        <li>
                          <a href="shop-compare.html">Shop Compare</a>
                        </li>
                        <li>
                          <a href="shop-wishlist.html">Shop Wishlist</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="shop-vendor-list.html">Vendors</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="shop-vendor-list.html">Vendors Listing</a>
                        </li>
                        <li>
                          <a href="shop-vendor-single.html">Vendor Single</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="#">Pages</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="page-about-us.html">About Us</a>
                        </li>
                        <li>
                          <a href="page-contact.html">Contact Us</a>
                        </li>
                        <li>
                          <a href="page-careers.html">Careers</a>
                        </li>
                        <li>
                          <a href="page-term.html">Term and Condition</a>
                        </li>
                        <li>
                          <a href="page-register.html">Register</a>
                        </li>
                        <li>
                          <a href="page-login.html">Login</a>
                        </li>
                        <li>
                          <a href="page-404.html">Error 404</a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="blog.html">Blog</a>
                      <ul className="sub-menu">
                        <li>
                          <a href="blog.html">Blog Grid</a>
                        </li>
                        <li>
                          <a href="blog-2.html">Blog Grid 2</a>
                        </li>
                        <li>
                          <a href="blog-list.html">Blog List</a>
                        </li>
                        <li>
                          <a href="blog-big.html">Blog Big</a>
                        </li>
                        <li>
                          <a href="blog-single.html">
                            Blog Single - Left sidebar
                          </a>
                        </li>
                        <li>
                          <a href="blog-single-2.html">
                            Blog Single - Right sidebar
                          </a>
                        </li>
                        <li>
                          <a href="blog-single-3.html">
                            Blog Single - No sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="page-contact.html">Contact</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <div className="mobile-header-top">
                  <div className="user-account">
                    <a href="page-account.html">
                      <img src="assets/imgs/template/ava_1.png" alt="Ecom" />
                    </a>
                    <div className="content">
                      <h6 className="user-name">
                        Hello<span className="text-brand"> Steven !</span>
                      </h6>
                      <p className="font-xs text-muted">
                        You have 3 new messages
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="mobile-menu">
                  <li>
                    <a href="page-account.html">My Account</a>
                  </li>
                  <li>
                    <a href="page-account.html">Order Tracking</a>
                  </li>
                  <li>
                    <a href="page-account.html">My Orders</a>
                  </li>
                  <li>
                    <a href="page-account.html">My Wishlist</a>
                  </li>
                  <li>
                    <a href="page-account.html">Setting</a>
                  </li>
                  <li>
                    <a href="page-login.html">Sign out</a>
                  </li>
                </ul>
              </div>
              <div className="mobile-banner">
                <div className="bg-5 block-iphone">
                  <span className="color-brand-3 font-sm-lh32">
                    Starting from $899
                  </span>
                  <h3 className="font-xl mb-10">iPhone 12 Pro 128Gb</h3>
                  <p className="font-base color-brand-3 mb-10">Special Sale</p>
                  <a className="btn btn-arrow" href="shop-grid.html">
                    learn more
                  </a>
                </div>
              </div>
              <div className="site-copyright color-gray-400 mt-30">
                Copyright 2022 &copy; Ecom - Marketplace Template.
                <br />
                Designed by
                <a href="http://alithemes.com" target="_blank">
                  &nbsp; AliThemes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Sidebar;

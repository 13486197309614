import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const userRegister = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/register_by_number", postData, {});
};

export const userLogin = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/user/login_by_otp", payload);
};

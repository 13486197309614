import { UserActionTypes, userInitialState } from "./user.model";
const reducer = (state = userInitialState, action) => {
  console.log(action.payload, "SADFFASD");
  console.log(userInitialState, "asdfg");
  switch (action.type) {
    case UserActionTypes.REGISTER_USER_REQUEST:
      return {
        ...state,
        isUserRegisterLoading: true,
        registerSuccess: false,
      };

    case UserActionTypes.REGISTER_USER_SUCCESS:
      console.log(action.payload.data.email, 4958);
      return {
        ...state,
        user_email: action.payload.data.email,
        isUserRegisterLoading: false,
        registerSuccess: true,
      };

    case UserActionTypes.REGISTER_USER_FAILURE:
      return {
        isUserRegisterLoading: false,
        registerSuccess: false,
      };

    case UserActionTypes.LOGIN_USER_REQUEST:
      return {
        ...state,
        isUserLoginLoading: true,
        loginSuccess: false,
      };

    case UserActionTypes.LOGIN_USER_SUCCESS:
      localStorage.setItem(
        "authKey",
        action?.payload?.data?.datas?.accessToken
      );
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("email", action?.payload?.data?.datas?.email);
      localStorage.setItem("role", action?.payload?.data?.datas?.role);
      localStorage.setItem("id", action?.payload?.data?.datas?.id);

      return {
        ...state,
        user: action.payload.data,
        isUserLoginLoading: false,
        loginSuccess: true,
      };

    case UserActionTypes.LOGIN_USER_FAILURE:
      return {
        email: null,
        isUserLoginLoading: false,
        loginSuccess: false,
      };

    case UserActionTypes.USERDETAIL_RESET:
      return {
        email: null,
        isUserDetailLoading: false,
      };

    case UserActionTypes.LOGINSUCCESS_RESET:
      return {
        loginSuccess: false,
        registerSuccess: false,
        user_email: null,
      };

    default:
      return state;
  }
};

export { reducer as UserReducer };

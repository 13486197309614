import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const signUp = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signup", payload);
};

export const signIn = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin", payload);
};
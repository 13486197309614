import { action } from "typesafe-actions";
import { AuthActionTypes } from "./auth.model";

export const signUpUserRequest = (req) =>
  action(AuthActionTypes.SIGNUP_USER_REQUEST, req);
export const signUpUserSuccess = (res) =>
  action(AuthActionTypes.SIGNUP_USER_SUCCESS, res);
export const signUpUserFailure = (err) =>
  action(AuthActionTypes.SIGNUP_USER_FAILURE, err);

export const clearAuthMessage = (err) =>
  action(AuthActionTypes.CLEAR_MESSAGES, err);

export const clearUserLogout = (res) =>
  action(AuthActionTypes.CLEAR_USER_LOGOUT, res);

export const loginUserRequest = (req) =>
  action(AuthActionTypes.LOGIN_USERDATA_REQUEST, req);
export const loginUserSuccess = (res) =>
  action(AuthActionTypes.LOGIN_USERDATA_SUCCESS, res);
export const loginUserFailure = (err) =>
  action(AuthActionTypes.LOGIN_USERDATA_FAILURE, err);




import { action } from "typesafe-actions";
import { cartActionTypes } from "./cart.model";

export const cartGetRequest = (req) =>
  action(cartActionTypes.CARTS_GET_REQUEST, req);
export const cartGetSuccess = (res) =>
  action(cartActionTypes.CARTS_GET_SUCCESS, res);
export const cartGetFailure = (err) =>
  action(cartActionTypes.CARTS_GET_FAILURE, err);

export const postAddToCardRequest = (req) =>
  action(cartActionTypes.ADDTOCART_POST_REQUEST, req);
export const postAddToCardsuccess = (res) =>
  action(cartActionTypes.ADDTOCART_POST_SUCCESS, res);
export const postAddToCardFailure = (err) =>
  action(cartActionTypes.ADDTOCART_POST_FAILURE, err);

export const resetInitialstate = (req) =>
  action(cartActionTypes.STATE_RESET_REQUEST, req);

export const checkOutDataRequest = (req) =>
  action(cartActionTypes.CHECKOUT_POST_REQUEST, req);
export const checkOutDataSuccess = (res) =>
  action(cartActionTypes.CHECKOUT_POST_SUCCESS, res);
export const checkOutDataFailure = (err) =>
  action(cartActionTypes.CHECKOUT_POST_FAILURE, err);

export const getOrderDetailRequest = (req) =>
  action(cartActionTypes.ORDERDETAIL_GET_REQUEST, req);
export const getOrderDetailSuccess = (req) =>
  action(cartActionTypes.ORDERDETAIL_GET_SUCCESS, req);
export const getOrderDetailFailure = (req) =>
  action(cartActionTypes.ORDERDETAIL_GET_FAILURE, req);

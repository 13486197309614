import { all, call, put, takeEvery } from "redux-saga/effects";
import * as UserActions from "./user.action";
import { UserActionTypes } from "./user.model";
import Swal from "sweetalert2";
import { userLogin, userRegister } from "./user.api";

export function* handleUserRegister(action) {
  try {
    const response = yield call(userRegister, action.payload);
    yield put(UserActions.userRegisterSuccess(response));
    Swal.fire({
      title: "OTP Send",
      text: "OTP Send to Given Mail Id successfully",
      icon: "success",
    });
  } catch (e) {
    yield put(UserActions.userRegisterFailure(e));
    Swal.fire({
      title: "OTP Send",
      text: "OTP send to given Mail Id failed",
      icon: "error",
    });
  }
}

export function* handleUserLogin(action) {
  try {
    const response = yield call(userLogin, action.payload);
    yield put(UserActions.userLoginSuccess(response));
  } catch (error) {
    yield put(UserActions.userLoginFailure(error));
    Swal.fire({
      title: "Login!",
      text: "Login Denied!",
      icon: "error",
    });
  }
}

export function* UserSaga() {
  yield takeEvery(UserActionTypes.REGISTER_USER_REQUEST, handleUserRegister);
  yield takeEvery(UserActionTypes.LOGIN_USER_REQUEST, handleUserLogin);
}

import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getProductApi = (query) => {
  console.log(query, "67888888888h");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/product?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&cat_id=" +
      query.cat_id +
      "&brand_id=" +
      query.brand_id +
      "&sortby=" +
      query.sortBy +
      "&order=" +
      query.order
  );
};
export const getProdcategoryApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/category/");
};

export const getBrandApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/brand/");
};
export const mailSendApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/product/send_email", payload);
};
